@media screen and (max-width: 3200px) {
  .header_image {
    width: 30%;
  }
  #whitepaper_image {
    width: 50%;
    margin: 50px;
  }
}
@media screen and (max-width: 480px) {
  .header_image {
    width: 60%;
  }
  #whitepaper_image {
    width: 85%;
    margin: 20px;
  }
  .nav_cw_button{
      width: 150px;
      margin: auto;
  }
}
